import * as React from "react";
import { SvgIcon } from "@material-ui/core";

const WantInTrade = (props) => {
    return (
        <SvgIcon {...props}>
            <path d="M22,7.07H9.66L11.9,4.83,9.07,2,2,9.07l7.07,7.07,2.83-2.83L9.66,11.07H22ZM18.46,15,22,18.54l-3.54,3.53-1.41-1.41,1.12-1.12H2v-2H18.17l-1.12-1.13L18.46,15Z" />
        </SvgIcon>
    );
};

export default WantInTrade;
