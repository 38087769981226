import * as React from "react";
import { useMutation } from "react-query";
import { useDispatch } from "react-redux";
import { v1 as uuidv1 } from "uuid";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
} from "@material-ui/core";
import { addMessage } from "../alerts/messagesSlice";
import { CardTable } from "../cards/CardTable";
import { addItems } from "./listsClient";
import { ListManagementItemOptions } from "./ListManagementItemOptions";
import { useUserPreferences } from "../account/hooks";
import { useUser } from "../users/hooks";

const listTitles = {
    MyCollection: `My collection`,
    WantInTrade: `Trades: Wanted`,
    OfferForTrade: `Trades: Offered`,
    Buying: `Sales: Wanted`,
    Selling: `Sales: Offered`,
};

/* Currently only for bulk adding to a list. */
export const ListManagementDialog = ({
    availableLanguages,
    availableStyles,
    cards,
    fullScreen,
    handleClose,
    listName,
    open,
}) => {
    const dispatch = useDispatch();
    const [selectionModel, setSelectionModel] = React.useState([]);
    const [itemOptions, setItemOptions] = React.useState({});
    const user = useUser();
    const { data: prefs } = useUserPreferences(user && user.id);
    if (prefs && itemOptions.quantity === undefined) {
        const defaultStyle = availableStyles.includes(prefs.default_card_style)
            ? prefs.default_card_style
            : availableStyles[0];
        const initialLanguage =
            prefs.default_card_language &&
            availableLanguages.includes(prefs.default_card_language)
                ? prefs.default_card_language
                : availableLanguages[0];
        setItemOptions({
            quantity: prefs.default_quantity || 1,
            condition: prefs.default_condition || 7,
            language: initialLanguage,
            style: defaultStyle,
        });
    }
    const { mutateAsync, isLoading } = useMutation(addItems, {
        onError: (err) => {
            dispatch(
                addMessage({
                    id: uuidv1(),
                    text: `Failed adding card to list: ${err}`,
                    priority: `low`,
                })
            );
        },
        onSuccess: (data, variables) => {
            setSelectionModel([]);
            dispatch(
                addMessage({
                    id: uuidv1(),
                    text: `Cards added to list`,
                    priority: `low`,
                })
            );
            handleClose();
        },
    });
    const handleSave = async () => {
        if (selectionModel.length < 1) {
            dispatch(
                addMessage({
                    id: uuidv1(),
                    text: `Please choose some cards`,
                    priority: `low`,
                })
            );
            return;
        }
        try {
            const preppedItems = selectionModel.map((card) => {
                return {
                    status: `published`,
                    card,
                    condition: itemOptions.condition,
                    language: itemOptions.language,
                    quantity: itemOptions.quantity,
                    foiled: itemOptions.style === `foiled`,
                    reverse_foiled: itemOptions.style === `reverse-foiled`,
                };
            });
            await mutateAsync({ listName, items: preppedItems });
        } catch {
            // Uh oh, something went wrong
        }
    };

    return (
        <Dialog
            aria-labelledby="dialog-title"
            fullScreen={fullScreen}
            fullWidth={true}
            maxWidth="lg"
            onClose={handleClose}
            open={open}
            scroll="body"
        >
            <DialogTitle id="dialog-title">
                Add cards to your <em>{listTitles[listName]}</em> list
            </DialogTitle>
            <DialogContent>
                <CardTable
                    cards={cards}
                    selectionModel={selectionModel}
                    setSelectionModel={setSelectionModel}
                />
                <ListManagementItemOptions
                    availableLanguages={availableLanguages}
                    availableStyles={availableStyles}
                    itemOptions={itemOptions}
                    listName={listName}
                    setItemOptions={setItemOptions}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>Cancel</Button>
                <Button
                    color="primary"
                    disabled={isLoading}
                    onClick={handleSave}
                    variant="contained"
                >
                    Save
                </Button>
            </DialogActions>
        </Dialog>
    );
};
