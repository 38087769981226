import * as React from "react";
import { SvgIcon } from "@material-ui/core";

const Buying = (props) => {
    return (
        <SvgIcon {...props}>
            <path d="M8.74,18.43v2.76H6.32V18.35a9.22,9.22,0,0,1-4.4-1.92l1.27-2.51a8,8,0,0,0,4.64,1.92c1,0,1.67-.38,1.67-1.12,0-2.27-6.79-.91-6.79-5.46,0-2,1.4-3.32,3.61-3.74V2.81H8.74V5.43a9.76,9.76,0,0,1,4.15,1.39L11.66,9.37A9.55,9.55,0,0,0,7.55,8c-.84,0-1.41.31-1.41.94,0,2.21,6.78.95,6.78,5.59C12.92,16.83,11.15,18.15,8.74,18.43Z" />
            <polygon points="22 13 17.83 13 18.95 14.12 17.54 15.54 14 12 17.54 8.46 18.95 9.88 17.83 11 22 11 22 13" />
        </SvgIcon>
    );
};

export default Buying;
