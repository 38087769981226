import * as React from "react";
import { useCardRarities } from "./hooks";

export const CardRarity = ({ rarityNum }) => {
    const { isSuccess, data: rarities } = useCardRarities(rarityNum);

    if (isSuccess) {
        const rarityName = rarities[rarityNum]
            ? rarities[rarityNum].name
            : `Unknown`;
        const rarityColour = rarities[rarityNum]
            ? rarities[rarityNum].colour
            : `unset`;
        return <span style={{ color: rarityColour }}>{rarityName}</span>;
    }

    return null;
};
