import * as React from 'react';
import {graphql} from 'gatsby';
import {Grid, Paper, Typography, useMediaQuery} from "@material-ui/core";
import {makeStyles, useTheme} from "@material-ui/core/styles";
import {Skeleton} from "@material-ui/lab";
import {useQuery} from "react-query";
import {Layout} from "../common/Layout";
import {SEO} from "../components/seo";
import {Heading} from "../components/heading";
import {CardImageList} from "../features/cards/CardImageList";
import {directusClient} from "../hooks/useDirectus";
import {SetDetailBlock} from "../components/SetDetailBlock";
import {ListManagementButtons} from "../features/lists/buttons/ListManagementButtons";
import {ListManagementDialog} from "../features/lists/ListManagementDialog";
import {BreadcrumbNav} from "../common/BreadcrumbNav";

// Query to be ran on build, passes resulting JSON as 'data' prop
export const query = graphql`
  query($setId: Int!) {
    directusSet(directusId: { eq: $setId }) {
      directusId
      name
      logo
      logo_url
      svg_icon
      description
      series {
          game {
            directusId
            name
            url_title
            languages
            available_standard
            available_foiled
            available_reverse_foiled
            available_signed
        }
      }
    }
  }
`;

const useStyles = makeStyles(theme => ({
    paper: {
        padding: theme.spacing(2),
        minHeight: `100vh`,
    },
}));

function getSetCards(setId) {
    return directusClient.get(`/items/card`, {
        fields: [`id`, `name`, `number`, `subtitle`, `rarity.id`, `image_url`, `image.data.asset_url`, `number`, `reference`, `type`, `url_title`],
        filter: {set: {eq: setId}},
        sort:`number,type,name`,
        limit: 4999
    });
}

// Basic set component
const SetTemplate = ({ pageContext, data }) => {
    const classes = useStyles();
    const theme = useTheme();
    const set = data.directusSet;
    const [dialogOpen, setDialogOpen] = React.useState(false);
    const [managedList, setManagedList] = React.useState(null);
    const [filteredCards, setFilteredCards] = React.useState([]);
    const [cards, setCards] = React.useState([]);
    const [disabledLists, setDisabledLists] = React.useState([`Selling`]);
    const fullScreenDialog = useMediaQuery(theme.breakpoints.down(`sm`));
    const {isLoading, isError, data:queryData, error} = useQuery([`cardsBySet`, set.directusId], () => getSetCards(set.directusId),{staleTime:86400000});

    const handleShowDialog = (listName) => {
        setManagedList(listName);
        setCards(listName === `MyCollection` ? queryData.data : filteredCards);
        setDialogOpen(true);
    }
    const handleDialogClose = () => {
        setDialogOpen(false);
    }
    React.useEffect(() => {
        // Prep filtered card set and any disabled lists.
        if (!isLoading && queryData) {
            // Filter out virtual cards
            const fCards = queryData.data.filter(card => !card.rarity || card.rarity.id !== 3)
            setFilteredCards(fCards);
            if (fCards.length === 0) {
                setDisabledLists([`OfferForTrade`,`WantInTrade`,`Selling`,`Buying`]);
            }
        }
    }, [isLoading, queryData]);
    const availableStyles = [`standard`,`foiled`,`reverse-foiled`];

    return (
        <Layout>
            <SEO description={`Trade cards in the ${set.series.game.name} set ${set.name}`} keywords={[set.series.game.name, set.name, `ccg`, `collectible`, `card`, `game`, `trade`]} title={set.name} />
            <section className="">
                <Grid container spacing={4}>
                    <Grid item xs={12}>
                        <Heading text={set.name} />
                        <BreadcrumbNav crumbs={[set.series.game.name, set.name]} />
                        <SetDetailBlock set={set} />
                    </Grid>
                    <Grid item xs={12}>
                        <Paper className="p-4" elevation={1}>
                        { isLoading ?
                            <Skeleton animation="pulse" height={48} variant="rect" />
                            : isError ?
                                <Typography style={{color: `red`}}>Failed loading cards: {error.message}</Typography>
                                : (
                                    <>
                                        <div className="flex flex-col md:flex-row">
                                            <div className="flex-shrink mt-3 px-2">
                                                Multi-add
                                            </div>
                                            <div className="flex-shrink px-2">
                                                <ListManagementButtons disabledLists={disabledLists} handleShowDialog={handleShowDialog} />
                                            </div>
                                        </div>

                                        <ListManagementDialog availableLanguages={set.series.game.languages} availableStyles={availableStyles} cards={cards} fullScreen={fullScreenDialog} handleClose={handleDialogClose} listName={managedList} open={dialogOpen}/>
                                    </>
                                )
                        }
                        </Paper>
                    </Grid>
                    <Grid item xs={12}>
                        <Paper className={classes.paper} elevation={1}>
                            { isLoading ?
                                <Skeleton animation="pulse" height={1000} variant="rect" />
                                : isError ?
                                    <Typography style={{color: `red`}}>Failed loading cards: {error.message}</Typography>
                                    : (
                                        <CardImageList cards={queryData.data} />
                                    )
                            }
                        </Paper>
                    </Grid>
                </Grid>
            </section>
        </Layout>
    );
};

export default SetTemplate;
