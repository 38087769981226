import * as React from "react";
import LibraryBooksOutlinedIcon from "@mui/icons-material/LibraryBooksOutlined";
import OfferedInTradeIcon from "../../../images/ui/icons/OfferedInTrade";
import WantInTradeIcon from "../../../images/ui/icons/WantInTrade";
import SellingIcon from "../../../images/ui/icons/Selling";
import BuyingIcon from "../../../images/ui/icons/Buying";
import { ListManagementButton } from "./ListManagementButton";
import { useAuth } from "../../../context/authContext";
import { LoginButton } from "../../account/LoginButton";

export const ListManagementButtons = ({
    disabledLists = [],
    handleShowDialog,
}) => {
    const buttons = [
        {
            listName: `MyCollection`,
            tooltip: `My collection`,
            icon: <LibraryBooksOutlinedIcon />,
            disabled: disabledLists.includes(`MyCollection`),
        },
        {
            listName: `OfferForTrade`,
            tooltip: `Trades: Offered`,
            icon: <OfferedInTradeIcon />,
            disabled: disabledLists.includes(`OfferForTrade`),
        },
        {
            listName: `WantInTrade`,
            tooltip: `Trades: Wanted`,
            icon: <WantInTradeIcon />,
            disabled: disabledLists.includes(`WantInTrade`),
        },
        {
            listName: `Selling`,
            tooltip: `Sales: Offered`,
            icon: <SellingIcon />,
            disabled: disabledLists.includes(`Selling`),
        },
        {
            listName: `Buying`,
            tooltip: `Sales: Wanted`,
            icon: <BuyingIcon />,
            disabled: disabledLists.includes(`Buying`),
        },
    ];
    const isSSR = typeof window === `undefined`;
    const { isLoggedIn } = useAuth();

    if (isSSR || !isLoggedIn()) {
        return buttons.map((button, index) => (
            <LoginButton
                color="primary"
                disabled={button.disabled}
                icon={button.icon}
                key={index}
                tooltip={button.tooltip}
                type="iconButton"
            />
        ));
    }

    return buttons.map((button, index) => (
        <ListManagementButton
            disabled={button.disabled}
            handleShowDialog={handleShowDialog}
            icon={button.icon}
            key={index}
            listName={button.listName}
            tooltip={button.tooltip}
        />
    ));
};
