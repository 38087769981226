import * as React from "react";
import ReactMarkdown from "react-markdown";
import { Paper, Typography } from "@material-ui/core";
import { SetIcon } from "../features/sets/SetIcon";

export const SetDetailBlock = ({ set }) => {
    let setIconProps = undefined;
    if (set.series.game.directusId === 158) {
        // Special white background for icons in these games: Pokemon
        setIconProps = {
            imgProps: {
                style: {
                    backgroundColor: `white`,
                },
            },
        };
    }
    return (
        <>
            <div className={`flex flex-col flex-wrap mt-8 sm:flex-row`}>
                <div className="flex-initial mr-8">
                    <SetIcon
                        altText={set.name}
                        fileId={set.logo}
                        gameUrlTitle={set.series.game.url_title}
                        logoUrl={set.logo_url}
                        svgIcon={set.svg_icon}
                        {...setIconProps}
                    />
                </div>
                {set.description && (
                    <Paper className="flex-1">
                        <Typography
                            className="markdown pt-4 px-4"
                            component="div"
                            variant="body2"
                        >
                            <ReactMarkdown>{set.description}</ReactMarkdown>
                        </Typography>
                    </Paper>
                )}
            </div>
        </>
    );
};
