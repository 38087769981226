import * as React from "react";
import { IconButton, Tooltip } from "@material-ui/core";

export const ListManagementButton = ({
    disabled = false,
    handleShowDialog,
    icon,
    listName,
    tooltip,
}) => {
    const color = `primary`;
    const handleClick = () => {
        handleShowDialog(listName);
    };
    const tt = disabled ? `${tooltip}: Not available` : tooltip;

    return (
        <Tooltip title={tt}>
            <span>
                <IconButton
                    aria-haspopup="true"
                    aria-label={tt}
                    color={color}
                    disabled={disabled}
                    onClick={handleClick}
                >
                    {icon}
                </IconButton>
            </span>
        </Tooltip>
    );
};
