import * as React from "react";
import { LanguageSelector } from "../../common/LanguageSelector";
import { CardStyleSelector } from "../cards/CardStyleSelector";
import { CardQuantitySelector } from "../cards/CardQuantitySelector";
import { CardConditionSelector } from "../cards/CardConditionSelector";

export const ListManagementItemOptions = ({
    availableLanguages,
    availableStyles,
    itemOptions,
    listName,
    setItemOptions,
}) => {
    const handleQuantityChange = (newQuantity) => {
        setItemOptions({ ...itemOptions, quantity: newQuantity });
    };
    const handleConditionChange = (event) => {
        setItemOptions({
            ...itemOptions,
            condition: Number(event.target.value),
        });
    };
    const handleLanguageChange = (langCode) => {
        setItemOptions({ ...itemOptions, language: langCode });
    };
    const handleStyleChange = (event) => {
        setItemOptions({ ...itemOptions, style: event.target.value });
    };
    /* TODO: move quantity to each card */
    return (
        <div className="flex flex-col md:flex-row">
            <div className="px-4">
                <CardQuantitySelector
                    fullWidth={false}
                    handleQuantityChange={handleQuantityChange}
                    quantity={itemOptions.quantity || 1}
                    style={{ marginTop: `12px` }}
                />
            </div>
            <div className="px-4">
                <CardConditionSelector
                    handleConditionChange={handleConditionChange}
                    listName={listName}
                    selectedOption={itemOptions.condition || 7}
                />
            </div>
            <div className="px-4">
                <LanguageSelector
                    availableLanguages={availableLanguages}
                    selected={itemOptions.language || availableLanguages[0]}
                    setSelected={handleLanguageChange}
                />
            </div>
            <div className="px-4">
                <CardStyleSelector
                    availableStyles={availableStyles}
                    handleStyleChange={handleStyleChange}
                    selectedOption={itemOptions.style || availableStyles[0]}
                />
            </div>
        </div>
    );
};
